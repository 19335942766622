import React from 'react';

import { QualificationsSlider } from './qualifications_slider/qualifications_slider';

import './qualifications.css';

let Qualifications = ({qualificationsRef}) => {
    return (
        <div id='qualifications_base' ref={qualificationsRef}>
            <h1>Qualifications</h1>

            <QualificationsSlider id='Qualifications'></QualificationsSlider>
        </div>
    );
}

export { Qualifications };