import React from 'react';

import './twitter.css';

let Twitter = () => {
    return (
        <div id='twitter'>
            <a href='https://twitter.com/drfknoble' target="_blank" rel="noopener noreferrer">
                <div id='icon'></div>
            </a>
        </div>
    );
}

export { Twitter };