import React from 'react';

import { StrengthsCard } from './strengths_card/strengths_card.js';

import './strengths.css';

let Strengths = ({strengthsRef}) => {
    return (
        <div id='strengths_base' ref={strengthsRef}>
            <h1>GALLUP<sup>&#174;</sup> CliftonStrengths<sup>&#174;</sup><br></br>  
                Signature Themes</h1>               
        
            <div id='strengths'>
                <StrengthsCard heading={'Achiever'}></StrengthsCard>
                <StrengthsCard heading={'Strategic'}></StrengthsCard>
                <StrengthsCard heading={'Responsible'}></StrengthsCard>
                <StrengthsCard heading={'Learner'}></StrengthsCard>
                <StrengthsCard heading={'Focus'}></StrengthsCard>
            </div>
        </div>
    );
}

export { Strengths };