import React from 'react';

import { SkillsCard } from './skills_card/skills_card';

import './skills.css';

let Skills = ({skillsRef}) => {
    return (
        <div id='skills_base' ref={skillsRef}>
            <h1>Skills</h1>

            <SkillsCard></SkillsCard>
        </div>
    );
}

export { Skills };