import React from 'react';

import { Linkedin } from '../../social/linkedin/linkedin.js';
import { Twitter } from '../../social/twitter/twitter.js';
import { GitHub } from '../../social/github/github.js';
import { YouTube } from '../../social/youtube/youtube.js';

import './connect_card.css';

let ConnectCard = () => {
    return (
        <div id='connect_card_base'>
            <h1>Let's Connect!</h1>

            <div id='me'></div>

            <p>Connect with me on LinkedIn or Twitter!</p>

            <div className='socials'>
                <Linkedin></Linkedin>
                <Twitter></Twitter>
            </div>

            <p>Check out my projects on GitHub and watch my videos on YouTube!</p>

            <div className='socials'>
                <GitHub url={'http://github.com/drfknoble'}></GitHub>
                <YouTube url={'https://youtube.com/@drfknoble'}></YouTube>
            </div>
        
        </div>
    );
}

export { ConnectCard };