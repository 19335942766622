import React from 'react';

import { ProjectSlider } from './project_slider/project_slider.js';

import './projects.css';

let Projects = ({ projectsRef }) => {
    return (
        <div id='projects_base' ref={projectsRef}>
            <h1>Sample Projects</h1>

            <ProjectSlider id='projects'></ProjectSlider>
              
        </div>
    );
}

export { Projects };