import React from 'react';

import { GitHub } from '../../../social/github/github.js';

import './project_card.css';

let ProjectCard = ({ logo, title, subtitle, authors, description, url }) => {
    return (
        <div id='project_card_base'>
            <div id='header'>
                <img
                    id='logo'
                    alt='logo'
                    src={logo}
                >
                </img>

                <div id='details'>
                    <div id='title'>
                        {title}
                    </div>
                    <div id='subtitle'>
                        {subtitle}
                    </div>
                    <div id='authors'>
                        {authors}
                    </div>
                </div>
            </div>

            <div id='description'>
                {description}
            </div>

            <div id='footer'>
                <GitHub url={url}></GitHub>
            </div>
        </div>
    );
}

export { ProjectCard };