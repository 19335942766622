import React from 'react';

import { ConnectCard } from './connect_card/connect_card.js';

import './connect.css';

let Connect = ({connectRef}) => {
    return (
        <div id='connect_base' ref={connectRef}>
            <ConnectCard></ConnectCard>
        </div>
    );
}

export { Connect };