import React from 'react';

import './footer.css';

let Footer = () => {
    return (
        <div id='footer_base'>
           
        </div>
    );
}

export { Footer };