import React from 'react';

import './navbar.css';

let scroll = (ref) => {
    window.scrollTo({
        top: ref.offsetTop,
        left: 0,
        behavior: 'smooth',
    });
};

let Navbar = ({ homeRef,
    aboutRef,
    experienceRef,
    skillsRef,
    strengthsRef,
    projectsRef,
    connectRef }) => {
    return (
        <div id='navbar_base'>
            <div className='navlink' onClick={() => { scroll(homeRef.current) }}>
                Home
            </div>
            <div className='navlink' onClick={() => { scroll(aboutRef.current) }}>
                About
            </div>
            <div className='navlink' onClick={() => { scroll(experienceRef.current) }}>
                Experience
            </div>
            <div className='navlink' onClick={() => { scroll(projectsRef.current) }}>
                Projects
            </div>
            <div className='navlink' onClick={() => { scroll(connectRef.current) }}>
                Connect
            </div>

        </div>
    );
};

export { Navbar }; 