import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

import { App } from './app';

let router = createBrowserRouter([
  {
    path: '/',
    element: <App></App>
  }
])

let container = document.getElementById('root');
let root = createRoot(container);

root.render(<RouterProvider router={router}/>);
