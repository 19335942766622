import React from 'react';
import { useState } from 'react';

import './skills_card.css';

let SkillsCard = () => {

    return (
        <div id='skills_card_base'>
            <div id='left_col'>
                <h2>Computer Aided Design</h2>
                <h2>Electronics Design Automation</h2>
                <h2>Programming</h2>
                <h2>Web Development</h2>
            </div>
            <div id='right_col'>
                <h2>System Design and Integration</h2>
                <h2>Mechatronics</h2>
                <h2>Robotics</h2>
                <h2>Machine Vision</h2>
                <h2>Machine Learning</h2>
            </div>
        </div>
    );
}

export { SkillsCard };