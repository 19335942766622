import React from 'react';

import './github.css';

let GitHub = ({url}) => {
    return (
        <div id='github'>
            <a href={url} target="_blank" rel="noopener noreferrer">
                <div id='icon'></div>
            </a>
        </div>
    );
}

export { GitHub };