import React from 'react';

import { AboutCard } from './about_card/about_card.js';

import './about.css';

let About = ({ aboutRef }) => {

    return (
        <div id='about_base' ref={aboutRef}>

            <AboutCard></AboutCard>

        </div>
    );
}

export { About };