import React from 'react';

import './strengths_card.css';

let StrengthsCard = ({heading}) => {
    return (
        <div id='strengths_card_base'>
            <h2>{heading}</h2>
        </div>
    );
};

export { StrengthsCard };