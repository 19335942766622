import React from 'react';

import './linkedin.css';

let Linkedin = () => {
    return (
        <div id='linkedin'>
            <a href='https://www.linkedin.com/in/drfknoble/' target="_blank" rel="noopener noreferrer">
                <div id='icon'></div>
            </a>
        </div>
    );
}

export { Linkedin };