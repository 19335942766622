import React from 'react';
import { useRef, useState } from 'react';

import { QualificationCard } from './qualification_card/qualification_card.js';

import massey_logo from './img/massey_logo.png';

import './qualifications_slider.css';

let QualificationsSlider = () => {

    let ref = useRef(null);

    let [scroll, setScroll] = useState(false);
    let [scrollX, setScrollX] = useState(0);
    let [clientX, setClientX] = useState(0);

    let handleMouseDown = (e) => {
        setScroll(true);
        setClientX(e.clientX);
    };

    let handleMouseUp = (e) => {
        setScroll(false);
    };

    let handleMouseMove = (e) => {
        if (scroll == true) {
            ref.current.scrollLeft = scrollX + e.clientX - clientX;
            setScrollX(scrollX + e.clientX - clientX);
            setClientX(e.clientX);
        }
    };

    return (
        <div
            id='qualifications_slider'
            ref={ref}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}>

            <QualificationCard
                logo={massey_logo}
                title={'PhD'}
                subtitle={'Mechatronics'}
                employer={'Massey University'}
                location={'Auckland, New Zealand'}
                duration={'2011 - 2014'}
                description={
                    <div>
                        <p><b>Description:</b></p>
                        <p>My PhD thesis is titled: “A Novel, Neuroscience-based Control Paradigm for Wearable Assistive Devices”, and it investigated how the human central nervous system controls locomotion and developed a controller for lower-extremity exoskeletons.</p>
                        <p>A key finding in the research was that the architecture of Central Pattern Generators (CPGs) found in the spinal cord, coupled with realistic models of neurons, can be used to control an assistive device and adapt to a user's unknown mass properties.</p>
                    </div>
                }
            ></QualificationCard>

            <QualificationCard
                logo={massey_logo}
                title={'ME (Hons)'}
                subtitle={'Mechatronics'}
                employer={'Massey University'}
                location={'Auckland, New Zealand'}
                duration={'2010 - 2011'}
                description={
                    <div>
                        <p><b>Description:</b></p>
                        <p>My MEng (Hons) thesis is titled: “Wireless Vehicle Presence Detection Using Self-Harvested Energy”, and it investigated how energy harvesting works and developed an electromagnetic-based energy harvesting system for wireless detection of vehicles.</p>
                        <p>A key finding in the research was that a magnet driven through a coil can induce a large enough voltage to turn on a low-power, ZigBee (802.15.4) wireless-based module, which can broadcast the presence of a vehicle to an embedded system.</p>
                    </div>
                }
            ></QualificationCard>

            <QualificationCard
                logo={massey_logo}
                title={'BE (Hons)'}
                subtitle={'Mechatronics'}
                employer={'Massey University'}
                location={'Auckland, New Zealand'}
                duration={'2004 - 2008'}
                description={
                    <div>
                        <p><b>Description:</b></p>
                        <p>My BEng (Hons) 4th Year Project focussed on the development of pneumatic-based, remote-controlled control system for a SAE go-cart. </p>
                        <p>A key output of the project was a remote-controlled actuator that could accelerate and decelerate the go-cart.</p>
                    </div>
                }
            ></QualificationCard>

        </div >
    );
};

export { QualificationsSlider };