import React from 'react';
import { useRef } from 'react';

import { Home } from './components/home/home.js';
import { Navbar } from './components/navbar/navbar.js';
import { About } from './components/about/about.js';
import { Experience } from './components/experience/experience.js';
import { Qualifications } from './components/qualifications/qualifications.js';
import { Skills } from './components/skills/skills.js';
import { Strengths } from './components/strengths/strengths.js';
import { Projects } from './components/projects/projects.js';
import { Connect } from './components/connect/connect.js';
import { Footer } from './components/footer/footer.js';

import './app.css';

let App = () => {
    let homeRef = useRef(null);
    let aboutRef = useRef(null);
    let experienceRef = useRef(null);
    let qualificationsRef = useRef(null);
    let skillsRef = useRef(null);
    let strengthsRef = useRef(null);
    let projectsRef = useRef(null);
    let connectRef = useRef(null);

    return (
        <div>
            <header>

            </header>

            <main>
                <Home homeRef={homeRef}></Home>

                <Navbar homeRef={homeRef} 
                        aboutRef={aboutRef} 
                        experienceRef={experienceRef}
                        skillsRef={skillsRef}
                        strengthsRef={strengthsRef}
                        projectsRef={projectsRef}
                        connectRef={connectRef}>
                </Navbar>

                <About aboutRef={aboutRef}></About>
                <Experience experienceRef={experienceRef}></Experience>
                <Qualifications qualificationsRef={qualificationsRef}></Qualifications>
                <Skills skillsRef={skillsRef}></Skills>
                <Strengths strengthsRef={strengthsRef}></Strengths>
                <Projects projectsRef={projectsRef}></Projects>
                <Connect connectRef={connectRef}></Connect>
                
            </main>

            <footer>
                <Footer></Footer>
            </footer>

        </div>
    )
};

export { App };