import React from 'react';

import './home.css';

let Home = ({homeRef}) => {
    return (
        <div id='home_base' ref={homeRef}>
            <div id='left_col'>

            </div>
            <div id='right_col'>
                <h1>Dr Frazer K. Noble</h1>
                <p><b>PhD, ME (Hons), BE (Hons)</b></p>
            </div>
        </div>
    );
}

export { Home };