import React from 'react';
import { useRef, useState } from 'react';

import { ProjectCard } from './project_card/project_card';

import lore_logo from './img/lore_back.png';
import more_logo from './img/more_front.png';
import pen_logo from './img/pen.png';
import project_logo from './img/project.png';
import './project_slider.css';

let ProjectSlider = () => {

    let ref = useRef(null);

    let [scroll, setScroll] = useState(false);
    let [scrollX, setScrollX] = useState(0);
    let [clientX, setClientX] = useState(0);

    let handleMouseDown = (e) => {
        setScroll(true);
        setClientX(e.clientX);
    };

    let handleMouseUp = (e) => {
        setScroll(false);
    };

    let handleMouseMove = (e) => {
        if (scroll == true) {
            ref.current.scrollLeft = scrollX + e.clientX - clientX;
            setScrollX(scrollX + e.clientX - clientX);
            setClientX(e.clientX);
        }
    };

    return (
        <div
            id='project_slider'
            ref={ref}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}>

            <ProjectCard
                logo={lore_logo}
                title={'LORE'}
                subtitle={<div>A Low-Cost, Open-Source<br />Robot for Education.</div>}
                authors={'Dr Frazer K. Noble'}
                description={
                    <div>
                        <p><b>Description:</b></p>
                        <p>Motivated by the lack of low-cost, e.g. less that NZD$100, mobile robots that could be used for educational purposes, I decided to make one myself. </p>

                        <p>My LORE robot has been used consistently by Massey University's School of Food and Advanced Technology (SF&AT) during experience days and workshop sessions.</p>
                    </div>
                }
                url={'http://github.com/drfknoble/lore'}
            ></ProjectCard>

            <ProjectCard
                logo={more_logo}
                title={'MORE'}
                subtitle={<div>My Other Robot<br />for Education.</div>}
                authors={'Dr Frazer K. Noble'}
                description={
                    <div>
                        <p><b>Description:</b></p>
                        <p>Motivated by the effects low-cost components had on my LORE robot's performance, I decided to make a new mobile robot using more robust components.</p>
                        <p>
                        My MORE robot performed a lot better than my LORE robot. I plan to use it in my Mechatronics, Robotics, and Automation courses.
                        </p>
                    </div>
                }
                url={'http://github.com/drfknoble/more'}
            ></ProjectCard>

            {/* <ProjectCard
                logo={pen_logo}
                title={'PEN'}
                subtitle={<div>A 3D-Printable Pen</div>}
                authors={'Dr Frazer K. Noble'}
                description={
                    <div>
                        <p><b>Description:</b></p>
                        <p>Motivated by my interest in 3D printing, sustainability, and writing, I designed a 3D-printable pen, which could easily recyled.</p>
                    </div>
                }
                url={'http://github.com/drfknoble/pen'}
            ></ProjectCard> */}

            <ProjectCard
                logo={project_logo}
                title={'GitHub'}
                subtitle={
                    <div>
                    </div>
                }
                authors={''}
                description={
                    <div>  
                    </div>
                }
                url={'http://github.com/drfknoble'}
            ></ProjectCard>

        </div >
    );
};

export { ProjectSlider };