import React from 'react';

import { Linkedin } from '../../social/linkedin/linkedin.js';
import { Twitter } from '../../social/twitter/twitter.js';
import { GitHub } from '../../social/github/github.js';
import { YouTube } from '../../social/youtube/youtube.js';

import './about_card.css';

let AboutCard = () => {
    return (
        <div id='about_card_base'>
            <h1>About Me</h1>

            <div id='me'></div>

            <div id='content'>
                <p>Hi there! My name is Frazer and welcome to my personal website.</p>

                <p>I'm an experienced Mechatronics Engineer with a PhD, a Master of Engineering with Honours (ME (Hons)), and a Bachelor of Engineering with Honours (BE (Hons)) in Mechatronics, with over 10 years experience lecturing and researching Mechatronics, Robotics, and Automation.</p>

                <p>I'm currently a Senior Lecturer of Mechatronics, Robotics, and Automation at Massey University's School of Food and Advanced Technology (SF&AT) here in Auckland, New Zealand.</p>

                <p>My research areas include:</p>

                <ul>
                    <li>Mechatronics,</li>
                    <li>Robotics,</li>
                    <li>Machine Vision,and </li>
                    <li>Machine Learning.</li>
                </ul>


                <p>I'm particularly interested in the development of intelligent, mechatronics systems.</p>
            </div>

            <p>Connect with me on LinkedIn or Twitter!</p>

            <div className='socials'>
                <Linkedin></Linkedin>
                <Twitter></Twitter>
            </div>

            <p>Check out my projects on GitHub and watch my videos on YouTube!</p>

            <div className='socials'>
                <GitHub url={'http://github.com/drfknoble'}></GitHub>
                <YouTube url={'https://youtube.com/@drfknoble'}></YouTube>
            </div>
        </div>
    );
}

export { AboutCard };