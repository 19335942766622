import React from 'react';
import { useRef, useState } from 'react';

import { ExperienceCard } from './experience_card/experience_card.js';

import massey_logo from './img/massey_logo.png';

import './experience_slider.css';

let ExperienceSlider = () => {

    let ref = useRef(null);

    let [scroll, setScroll] = useState(false);
    let [scrollX, setScrollX] = useState(0);
    let [clientX, setClientX] = useState(0);

    let handleMouseDown = (e) => {
        setScroll(true);
        setClientX(e.clientX);
    };

    let handleMouseUp = (e) => {
        setScroll(false);
    };

    let handleMouseMove = (e) => {
        if (scroll == true) {
            ref.current.scrollLeft = scrollX + e.clientX - clientX;
            setScrollX(scrollX + e.clientX - clientX);
            setClientX(e.clientX);
        }
    };

    return (
        <div
            id='experience_slider'
            ref={ref}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}>
           
            <ExperienceCard
                logo={massey_logo}
                role={'Senior Lecturer'}
                subrole={'Mechatronics'}
                employer={'Massey University'}
                location={'Auckland, New Zealand'}
                duration={'Feb 2020 - Present'}
                description={
                    <div>
                        <p><b>Responsibilities:</b></p>
                        <ul>
                            <li>Undertake teaching, research, and consulting in the area of mechatronics, robotics, automation.</li>
                            <li>Mechatronics Major Leader.</li>
                        </ul>
                    </div>
                }
            ></ExperienceCard>
            <ExperienceCard
                logo={massey_logo}
                role={'Lecturer'}
                subrole={'Mechatronics'}
                employer={'Massey University'}
                location={'Auckland, New Zealand'}
                duration={'Feb 2013 - Feb 2020'}
                description={
                    <div>
                        <p><b>Responsibilities:</b></p>
                        <ul>
                            <li>Undertake teaching, research, and consulting in the area of mechatronics, robotics, automation.</li>
                        </ul>
                    </div>
                }
            ></ExperienceCard>
            <ExperienceCard
                logo={massey_logo}
                role={'Assistant Lecturer'}
                subrole={'Mechatronics'}
                employer={'Massey University'}
                location={'Auckland, New Zealand'}
                duration={'Oct 2011 - Feb 2013'}
                description={
                    <div>
                        <p><b>Responsibilities:</b></p>
                        <ul>
                            <li>Undertake teaching and research in the area of mechatronics, robotics, automation.</li>
                        </ul>
                    </div>
                }
            ></ExperienceCard>
            <ExperienceCard
                logo={massey_logo}
                role={'Tutor'}
                subrole={'Mechatronics'}
                employer={'Massey University'}
                location={'Auckland, New Zealand'}
                duration={'Feb 2011 - Oct 2011'}
                description={
                    <div>
                        <p><b>Responsibilities:</b></p>
                        <ul>
                            <li>Undertake teaching in the area of mechatronics, robotics, automation.</li>
                        </ul>
                    </div>
                }
            ></ExperienceCard>
        </div >
    );
};

export { ExperienceSlider };