import React from 'react';

import { ExperienceSlider } from './experience_slider/experience_slider';

import './experience.css';

let Experience = ({experienceRef}) => {
    return (
        <div id='experience_base' ref={experienceRef}>
            <h1>Experience</h1>

            <ExperienceSlider id='experiences'></ExperienceSlider>
        </div>
    );
}

export { Experience };